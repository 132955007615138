import React from 'react'
import './Ticker.css'

export default function Ticker() {
  return (
    <>
<div>
  <div className="ticker-wrap">
    <div className="ticker">
      {/* <div className="ticker__item">From snapshot to showcase.</div>
      <div className="ticker__item">From snapshot to showcase.</div>
      <div className="ticker__item">From snapshot to showcase.</div>
      <div className="ticker__item">From snapshot to showcase.</div> */}

<div className="ticker__item">From snapshot to showcase. From snapshot to showcase. From snapshot to showcase. From snapshot to showcase.</div>

    </div>
  </div>
</div>


    </>
  )
}
