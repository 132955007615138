// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Text from './Component.js/Text';
import Outlethome from './Component.js/Outlethome';
import Home from './Component.js/Home';
import Mainframe from './Component.js/Mainframe';
import Collage from './Component.js/Collage';
import Collegeframes from './Component.js/Collegeframes';
import Potratecollege from './Component.js/Potratecollege';
import Squareframes from './Component.js/Squareframes';
import Squarecollege from './Component.js/Squarecollege';
import Canvasframe from './Component.js/Canvasframe';
import Canvastype from './Component.js/Canvastype';
import Carttext from './Component.js/Carttext';
import Checkout from './Component.js/Checkout';
import Shoppinghome from './Shopping.js/Shoppinghome';
import Productdetails from './Shopping.js/Productdetails';
import Papertype from './Component.js/Papertype';
import Papperprint from './Component.js/Papperprint';
import Simplecontextprovider from './Component.js/Simplecontext';
import Success from './Shopping.js/Success';
import Failure from './Shopping.js/Failure';
import Profilepage from './Profilepages.js/Profilepage';
import Userorders from './Profilepages.js/Userorders';
import Privacy from './Aboutpages.js/Privacy';
import Terms from './Aboutpages.js/Terms';
import Miniframetypes from './Component.js/Miniframetypes';
import Aboutus from './Aboutpages.js/Aboutus';
import Forgotpassword from './Component.js/Forgotpassword';
import AboutUs2 from './NewDesignPages/AboutUs2';
import Home2 from './NewDesignPages/Home/Home2';
import Frames from './NewDesignPages/Frames';
import Contact2 from './NewDesignPages/Contact2';
import PrintFrame from './NewDesignPages/Frames/PrintFrame';
import Blogs from './NewDesignPages/Blog/Blogs';
import PrivacyPolicy2 from './NewDesignPages/PrivacyPolicy2';
import TermsOfUse2 from './NewDesignPages/TermsOfUse2';
import BlogContent from './NewDesignPages/Blog/BlogContent';
import PrintUpload from './NewDesignPages/Frames/Uploadings/PrintUpload';
import MiniFrame2 from './NewDesignPages/Frames/MiniFrame2';
import CanvasFrame2 from './NewDesignPages/Frames/CanvasFrame2';
import CollageFrame2 from './NewDesignPages/Frames/CollageFrame2';
import MiniUpload from './NewDesignPages/Frames/Uploadings/MiniUpload';
import CanvasUpload from './NewDesignPages/Frames/Uploadings/CanvasUpload';
import LandscapeCollage from './NewDesignPages/Frames/Uploadings/LandscapeCollage';
import PortraitCollage from './NewDesignPages/Frames/Uploadings/PortraitCollage';
import CollageSquareFrames from './NewDesignPages/Frames/Uploadings/CollageSquareFrames';
import SquareCollage from './NewDesignPages/Frames/Uploadings/SquareCollage';
import Shopping2 from './NewDesignPages/Shopping2';
import Cart from './NewDesignPages/Cart';




function App() {
  return (
    <div>
      <BrowserRouter>
      <Simplecontextprovider>
      <Routes>
        <Route exact path='text' element={<Text/>} />
        {/* http://localhost:3000/success=true&session_id=cs_test_a1YiEzo9jP8OjjuLVmFy6kvBle9PU71D1bmh8pL3Pwo6mKBKTHWBPxAYM2 */}
        <Route  path="/success=true&session_id=/:id/:order_id" element={<Success/>} />  
        <Route  path={`/canceled=true&order_id=/:order_id` } element={<Failure/>} />
        <Route path='/forgotpassword/:id' element={<Forgotpassword/>} />
        <Route exact path='text' element={<Text/>} />
        <Route path='/' element={<Outlethome/>}>
          {/* <Route index element={<Home/>} /> */}
          <Route index element={<Home2/>} />
          {/* <Route path='miniframe' element={<Mainframe/>} /> */}
          
          {/* <Route path='college' element={<Collage/>} /> */}
          {/* <Route path='collegeframe' element={<Collegeframes/>} /> */}
          {/* <Route path='potratecollege' element={<Potratecollege/>} /> */}
          {/* <Route path='squareframes' element={<Squareframes/>} /> */}
          {/* <Route path='squarecollege' element={<Squarecollege/>} /> */}
          {/* <Route path='canavsframe' element={<Canvasframe/>} /> */}
          {/* <Route path='canvastype' element={<Canvastype/>} /> */}
          {/* <Route path='carttext' element={<Carttext/>} /> */}
          <Route path='checkout' element={<Checkout/>} />
          {/* <Route path='shopping' element={<Shoppinghome/>}/> */}
          <Route path='productdetails/:prod_id' element={<Productdetails/>}/>
          {/* <Route path='papertype' element={<Papertype/>}/> */}
          {/* <Route path='paperprint' element={<Papperprint/>}/> */}
          <Route path='profile' element={<Profilepage/>}/>
          <Route path='userorder' element={<Userorders/>}/>
          {/* <Route path='privacypolicy' element={<Privacy/>}/> */}
          {/* <Route path='terms' element={<Terms/>}/> */}
          {/* <Route path='aboutus' element={<Aboutus/>}/> */}
          {/* <Route path='framestype' element={<Miniframetypes/>}/> */}
          <Route path='about-us' element={<AboutUs2/>}/>
          <Route path='frames' element={<Frames/>} />
          <Route path='contact' element={<Contact2/>}/>
          <Route path='print-frame' element={<PrintFrame/>}/>
          <Route path='blogs' element={<Blogs/>}/>
          <Route path='privacy-policy' element={<PrivacyPolicy2/>}/>
          <Route path='terms' element={<TermsOfUse2/>}/>
          <Route path='blog-details/:blog_id' element={<BlogContent/>}/>
          <Route path='upload-print' element={<PrintUpload/>}/>
          <Route path='mini-frame' element={<MiniFrame2/>}/>
          <Route path='canvas-frame' element={<CanvasFrame2/>}/>
          <Route path='collage-frame' element={<CollageFrame2/>}/>
          <Route path='upload-mini' element={<MiniUpload/>}/>
          <Route path='upload-canvas' element={<CanvasUpload/>}/>
          <Route path='landscape-collage' element={<LandscapeCollage/>}/>
          <Route path='portrait-collage' element={<PortraitCollage/>}/>
          <Route path='square-frames' element={<CollageSquareFrames/>}/>
          <Route path='square-collage' element={<SquareCollage/>}/>
          <Route path='shopping' element={<Shopping2/>}/>
          <Route path='cart' element={<Cart/>}/>
        </Route>
      </Routes>
      </Simplecontextprovider>
      </BrowserRouter>
    </div>
  );
}

export default App;
