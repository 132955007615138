import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ leftSidebar, setleftSidebar }) {
  const sidebarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setleftSidebar(false);
      }
    }

    if (leftSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [leftSidebar]);

  return (
    <div>
      <div
        class={`offcanvas offcanvas-start ${leftSidebar ? "show" : ""}`}
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        ref={sidebarRef}
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setleftSidebar(false)}
          ></button>
        </div>
        <div class="offcanvas-body" style={{ margin: "0 50px" }}>
          <div className="offcanvas-sections d-flex flex-column">
            <div className="mb-3">
              <h4 className="widget-title text-white mb-3">Products</h4>
              <ul className="list-unstyled sidebar-list">
                <li>
                  <Link to="/print-frame" onClick={() => setleftSidebar(false)}>
                    Print & Frame
                  </Link>
                </li>
                <li>
                  <Link to="/mini-frame" onClick={() => setleftSidebar(false)}>
                    Wall Frames
                  </Link>
                </li>
                <li>
                  <Link
                    to="/canvas-frame"
                    onClick={() => setleftSidebar(false)}
                  >
                    Canvas
                  </Link>
                </li>
                <li>
                  <Link
                    to="/collage-frame"
                    onClick={() => setleftSidebar(false)}
                  >
                    Collage Frames
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="widget-title text-white mb-3">Foto Frames</h4>
              <ul className="list-unstyled sidebar-list">
                <li>
                  <Link to="/about-us" onClick={() => setleftSidebar(false)}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/blogs" onClick={() => setleftSidebar(false)}>
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={() => setleftSidebar(false)}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
