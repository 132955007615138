import React, { useContext, useEffect, useState } from "react";
import * as filestack from "filestack-js";
import { GrPowerReset } from "react-icons/gr";
import { FaUpload } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from "react-toastify";
import { Simplecontext } from "../../../Component.js/Simplecontext";
import { apikey } from "../../../Component.js/filestackapikey";

export default function PrintUpload() {
  const { framedata, Getframe, framepricedata } = useContext(Simplecontext);
  const [uploaded_images, setuploaded_images] = useState([]);
  var client = filestack.init(apikey);
  const [papervalue, setpapervalue] = useState("MATTE");
  const [frame, setframe] = useState("");
  const [selectitm, setselectitm] = useState("");
  const [pricelist, setpricelist] = useState("");
  let location = useLocation();
  let navigate = useNavigate();
  let frametype = location.state.string;

  useEffect(() => {
    Upload_Product_Image();
    window.scrollTo(0, 0);
    // to prevent reload of page
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      // return "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const notify = (msg) =>
    toast.success(msg, {
      position: "top-left",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const Upload_Product_Image = (reset = false) => {
    if (reset) {
      setuploaded_images([]); // Clear previous images
    }

    const options = {
      fromSources: ["local_file_system", "instagram", "facebook"],
      accept: ["image/*"],
      transformations: {
        crop: {
          aspectRatio:
            frametype === "landscape"
              ? 3 / 2
              : frametype === "portait"
              ? 2 / 3
              : 1 / 1,
          force: true,
        },
      },
      maxFiles: 1,
      minFiles: 1,
      uploadInBackground: false,
      onUploadDone: (res) => {
        if (res.filesUploaded.length !== 0) {
          var newArray = [];
          res.filesUploaded.forEach((element) => {
            newArray.push(element.url);
          });
          // setuploaded_images(() => [...newArray]);
          setuploaded_images(newArray);
        }
      },
    };
    client.picker(options).open();
  };
  const handlerprice = () => {
    let data = framepricedata
      .filter((t) => t.frame === "print")
      .filter((t) => t.orientation === frametype);

    if (data.length) {
      return data[0].price;
    }
    return null;
  };
  const addtocart = (pricetag) => {
    try {
      let cart_list = [];
      let body = {
        total_price: pricetag.split("-")[2],
        image_url: uploaded_images,
        orientation: frametype,
        size: pricetag.split("-")[1],
        // product_type :"",
        frame_look: "",
        product_name: "Print",
        frame_type: "",
        frame_image: "/assets/img/photos/print.png",
        frame: frame ? selectitm : "",
        papper: papervalue,
        quantity: 1,
        vat: "",
        shipping: "",
      };
      if (window.localStorage.getItem("ffcart")) {
        cart_list = window.localStorage.getItem("ffcart");
      }
      if (cart_list.length) {
        cart_list = JSON.parse(cart_list);
      }
      let c_list = cart_list.concat(body);
      window.localStorage.setItem("ffcart", JSON.stringify(c_list));
      return navigate("/cart");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="container mt-18 mb-13">
        <div className="row padd">
          {/* <ToastContainer/>  */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className=" photocard_style ">
              <div className="card-body minibackgound ">
                <div
                  className="text-center"
                  style={
                    uploaded_images.length
                      ? { display: "block" }
                      : { display: "none" }
                  }
                ></div>
                <div className="overflowbar m-auto">
                  {uploaded_images.length ? (
                    <>
                      {/* <div className="box-shadow p-1" style={{width:"410px",margin:"auto"}} > */}
                      {uploaded_images.length
                        ? uploaded_images.map((itm, k) => (
                            <div key={k} className="  ">
                              <div
                                className="box-shadow p-1 "
                                style={{ width: "50%", margin: "auto" }}
                              >
                                <img
                                  src={itm}
                                  alt="img"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          ))
                        : null}
                      {/* </div> */}
                    </>
                  ) : (
                    <div>
                      <p>No Images Selected</p>
                      <div className="col-6 col-md-3">
                        <div className="mt-2 item ">
                          <div className="text-center upload_card ">
                            <div
                              className=""
                              onClick={() => Upload_Product_Image()}
                            >
                              <FaUpload size={50} color={"black"} />
                              <h6>upload images</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="text-center mt-5"
                  style={
                    uploaded_images.length
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <span
                    onClick={() => {
                      // setuploaded_images([]);
                      Upload_Product_Image(true);
                    }}
                    className=" reset-link"
                  >
                    <GrPowerReset color="white" /> reset image
                  </span>
                </div>
                {/* <img src="\assets\img\photos\collage-622.jpg" width={150} height={150} alt="img" /> */}
              </div>
            </div>
          </div>
          <div className=" col-12 col-md-6 col-lg-6  ">
            <div
              className="p-2 my-5  "
              style={
                uploaded_images.length
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              {/* <div className='line-break'/> */}
              <div className=" p-3">
                <div>
                  <h3 className="pb-1  ">Print</h3>
                </div>
                <label className="ps-0">
                  <strong
                    className={pricelist ? "d-block" : "d-none"}
                    style={{ color: "#cd0202 ", fontSize: "20px" }}
                  >
                    {pricelist?.split("-")?.[2] *
                      (uploaded_images?.length ?? 1) ?? ""}
                    <span className="aedsize"> AED</span>{" "}
                  </strong>
                </label>

                {/* <div className='line-break'/>      
          <div className='mb-3'>
              <label className='ps-0 mb-2'><strong className='text-dark'>{frame} Frame</strong></label><br/>
              <div className=' table-responsive row pb-2'>
              
              {framedata.map((itm,k)=>(
                  <div key={k} className='col-4 col-md-3 col-lg-3 py-2'>
                  <img className='frameimage ' style={frame===itm.framename?{border:"2px solid black"}:{}} onClick={()=>setframe(itm.framename) & setselectitm(itm)} src={itm.main_image} width={80} height={57} alt="img" />
                 </div>
                ))} 
              
              </div>
            </div> */}

                <div className="line-break" />
                <label className="ps-0">
                  <strong className="text-dark">Paper</strong>
                </label>
                <br />
                <div className="card3">
                  <div className="form">
                    <label>
                      <input
                        type="radio"
                        className="input-radio off"
                        name="pilih"
                        onClick={(e) =>
                          e.target.value ? setpapervalue("MATTE") : ""
                        }
                        value="true"
                        defaultChecked
                      />{" "}
                      Matte
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="input-radio on"
                        defaultChecked
                        name="pilih"
                        onClick={(e) =>
                          e.target.value ? setpapervalue("GLOSS") : ""
                        }
                      />
                      Gloss
                    </label>
                  </div>
                </div>

                {/* <div className="switch-field ">
              <input type="radio" id="radio-one" name="switch-one" onClick={(e)=>e.target.value?setpapervalue("MATTE"):""} value="true" defaultChecked/>
              <label className='label1' htmlFor="radio-one">MATTE</label>
              <input type="radio" id="radio-two" name="switch-one" onClick={(e)=>e.target.value?setpapervalue("GLOSS"):""} defaultValue="GLOSS" />
              <label className='label2' htmlFor="radio-two">GLOSS</label>
            </div> */}

                <div className="line-break" />

                <label className="ps-0 mb-2">
                  <strong className="text-dark">Print Size</strong>
                </label>
                <br />
                <div className="form-select-wrapper">
                  <select
                    required
                    onChange={(e) => setpricelist(e.target.value)}
                    className="form-select form-select-md "
                  >
                    <option value="" hidden>
                      select size
                    </option>
                    {handlerprice()
                      ? handlerprice()
                          .split(",")
                          .map((itm, k) => (
                            <option key={k} value={itm}>
                              {itm.split("-")[1]} &emsp;&emsp;&emsp;&emsp;
                              {itm.split("-")[2]} AED{" "}
                            </option>
                          ))
                      : null}
                    {/* <option value={"45 x 30 cm"} >45 x 30 cm &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; AED 150</option>
                    <option value={"45 x 30 cm"} >45 x 30 cm &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; AED 150</option> */}
                  </select>
                </div>
              </div>
              <div className="container">
                <button
                  onClick={() =>
                    pricelist
                      ? addtocart(pricelist)
                      : notifyerror("select size")
                  }
                  className="btn btn-primary rounded w-100 mt-0"
                  style={{
                    backgroundColor: "#3e4192",
                    borderColor: "#3e4192",
                    fontWeight: 500,
                  }}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
          {/* <div className=' col-12 col-md-4 col-lg-4  '>
        <div className='p-2 my-5 border-cart 'style={uploaded_images.length?{display:"block"}:{display:'none'}} >
          
          <div className="table-responsive p-3">
          <div><h3 className="pb-1 ">Print</h3></div>
          <div className='line-break'/>
          <label className="ps-0"><strong className="text-dark">Papper</strong></label>            
            <div className="switch-field ">
            <input type="radio" id="radio-one" name="switch-one" onClick={(e)=>e.target.value?setpapervalue("MATTE"):""} value="true" defaultChecked/>
            <label className='label1' htmlFor="radio-one">MATTE</label>
            <input type="radio" id="radio-two" name="switch-one" onClick={(e)=>e.target.value?setpapervalue("GLOSS"):""} defaultValue="GLOSS" />
            <label className='label2' htmlFor="radio-two">GLOSS</label>
        </div>
            
            
                <div className='line-break'/>
                <div className='mb-2'>
              <label className='ps-0 mb-2'><strong className='text-dark'>Frame Size</strong></label><br/>
              <div className='form-select-wrapper'>
              <select required onClick={(e)=>setpricelist(e.target.value)} className="form-select form-select-md ">
                  <option value="" hidden>select size</option>
                    {handlerprice()?handlerprice().split(',').map((itm,k)=>(
                    <option key={k} value={itm}  >{itm.split('-')[1]} &emsp;&emsp;&emsp;&emsp;{itm.split('-')[2]} AED </option>
                    )):null}
                    
                    
                   
                  </select>
              
              
              </div>
            </div>
                  
          </div>
            <div className='container'>
              <button onClick={()=>pricelist? addtocart(pricelist):notifyerror("select size")} className="btn btn-primary rounded w-100 mt-0">ADD TO CART</button>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
