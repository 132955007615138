import React, { useContext, useEffect, useState } from "react";
import * as filestack from "filestack-js";
import { RxCross2 } from "react-icons/rx";
import { FaUpload } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Callaxios from './Callaxios';
import { ToastContainer, toast } from "react-toastify";
import { Simplecontext } from "../../../Component.js/Simplecontext";
import { apikey } from "../../../Component.js/filestackapikey";
// import 'react-toastify/dist/ReactToastify.css'

export default function MiniUpload() {
  const { framepricedata, Getframeprice, framedata } =
    useContext(Simplecontext);
  const [uploaded_images, setuploaded_images] = useState([]);
  var client = filestack.init(apikey);
  const [papervalue, setpapervalue] = useState("MATTE");
  const [framematerial, setframematerial] = useState("CLASSIC");
  const [frame, setframe] = useState();
  const [pricelist, setpricelist] = useState("");
  const [selectitm, setselectitm] = useState({
    image: "/assets/img/photos/black-frame.png",
  });
  const location = useLocation();
  let frametype = location?.state?.string ?? "";
  let navigate = useNavigate();
  useEffect(() => {
    Upload_Product_Image();
    window.scrollTo(0, 0);
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      // return "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const notify = (msg) =>
    toast.success(msg, {
      position: "top-left",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const frameselect = () => {
    let data = framedata.filter((t) =>
      t.framename.toLowerCase().includes("black")
    );

    return data[0];
  };
  const Upload_Product_Image = () => {
    const options = {
      fromSources: ["local_file_system", "instagram", "facebook"],
      accept: ["image/*"],
      transformations: {
        crop: {
          aspectRatio:
            frametype === "square"
              ? 4 / 4
              : frametype === "landscape"
              ? 3 / 2
              : 2 / 3,
          force: true,
        },
      },
      maxFiles: 10,
      minFiles: 1,
      uploadInBackground: false,
      onUploadDone: (res) => {
        if (res.filesUploaded && res.filesUploaded.length !== 0) {
          var newArray = [...uploaded_images];
          res.filesUploaded.forEach((element) => {
            newArray.push(element.url);
          });
          setuploaded_images(newArray);
        }
      },
    };
    client.picker(options).open();
  };
  const removeimage = (k) => {
    let imagelist = uploaded_images;
    imagelist.splice(k, 1);
    setuploaded_images(() => [...imagelist]);
  };

  const handlerprice = () => {
    let data = framepricedata
      .filter((t) => t.frame === "miniframe")
      .filter((t) => t.orientation === frametype)
      .filter((t) => t.price.split("-")[0] === "1");

    if (data.length) {
      return data[0].price;
    }
    return null;
  };

  const addtocart = (pricetag) => {
    try {
      let cart_list = [];
      let body = {
        total_price: pricetag.split("-")[2] * uploaded_images.length,
        image_url: uploaded_images,
        orientation: frametype,
        size: pricetag.split("-")[1],
        // product_type :"",
        frame_look: framematerial,
        product_name: "Mini Frame",
        frame_type: "",
        frame_image: "/assets/img/photos/mini-frames-black.png",
        frame: selectitm,
        papper: papervalue,
        quantity: 1,
        vat: "",
        shipping: "",
      };
      if (window.localStorage.getItem("ffcart")) {
        cart_list = window.localStorage.getItem("ffcart");
      }
      if (cart_list.length) {
        cart_list = JSON.parse(cart_list);
      }
      let c_list = cart_list.concat(body);
      window.localStorage.setItem("ffcart", JSON.stringify(c_list));
      return navigate("/cart");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="container mt-18 mb-13">
        <div className="row padd">
          {/* <ToastContainer/> */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className=" photocard_style ">
              <div className="card-body minibackgound ">
                <div className="row col-12">
                  {uploaded_images.map((itm, k) => (
                    <div key={k} className="col-6 ">
                      {/* <div className="mt-2 item">
              <figure className='framebox-shadow' >
              <img src={selectitm?selectitm?.image??"/assets/img/photos/black-frame.png":"/assets/img/photos/black-frame.png"} alt="example"  style={{width:"100%",height:''}} /> 
              <img src={itm} alt="img" className='minimage' style={framematerial==="MODERN"?{width:"94%"}:{width:"94%",padding:"10px"}} />             
                <button onClick={()=>removeimage(k)} className="item-cart"><RxCross2 className='mt-1'/> remove</button>
              </figure>
            </div> */}
                      <div
                        className={" d-flex border-cp framebox-shadow"}
                        style={{
                          width: "100%",
                          height: "100%",
                          margin: "auto",
                          borderImage: `url(${
                            selectitm?.image ??
                            "/assets/img/photos/black-frame.png"
                          })1%  stretch repeat`,
                        }}
                      >
                        <img
                          src={itm}
                          alt="img"
                          className=""
                          style={
                            framematerial === "MODERN"
                              ? { width: "100%" }
                              : { width: "100%", padding: "10px" }
                          }
                        />
                      </div>
                    </div>
                  ))}

                  <div className="col-6 col-md-4">
                    <div className="mt-2 item ">
                      <div className="text-center upload_card ">
                        <div
                          className=""
                          onClick={() => Upload_Product_Image()}
                        >
                          <FaUpload size={50} color={"black"} />
                          <h6>upload images</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <img src="\assets\img\photos\collage-622.jpg" width={150} height={150} alt="img" /> */}
              </div>
            </div>
          </div>
          <div className=" col-12 col-md-6 col-lg-6  ">
            <div
              className="p-2 my-5  "
              style={
                uploaded_images.length
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              {/* <div className='line-break'/> */}
              <div className="pb-3 ">
                <div>
                  <h3 className="pb-1  ">Frames</h3>
                </div>
                <label className="ps-0">
                  <strong
                    className={pricelist ? "d-block" : "d-none"}
                    style={{ color: "#cd0202 ", fontSize: "20px" }}
                  >
                    {uploaded_images?.length
                      ? framepricedata
                        ? pricelist?.split("-")[2] * uploaded_images.length
                        : ""
                      : ""}
                    <span className="aedsize">AED</span>
                  </strong>
                </label>

                <div className="line-break" />
                <div className="mb-3">
                  <label className="ps-0 mb-2">
                    <strong className="text-dark">{frame} Frame</strong>
                  </label>
                  <br />
                  <div className=" table-responsive row pb-2">
                    {framedata.map((itm, k) => (
                      <div key={k} className="col-4 col-md-3 col-lg-3 py-2">
                        <img
                          className="frameimage "
                          style={
                            frame === itm.framename
                              ? { border: "2px solid black" }
                              : {}
                          }
                          onClick={() =>
                            setframe(itm.framename) & setselectitm(itm)
                          }
                          src={itm.main_image}
                          width={80}
                          height={57}
                          alt="img"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="line-break" />
                <label className="ps-0">
                  <strong className="text-dark">Paper</strong>
                </label>
                <br />
                <div className="card3">
                  <div className="form">
                    <label>
                      <input
                        type="radio"
                        className="input-radio on"
                        defaultChecked
                        name="pilih"
                        onClick={(e) =>
                          e.target.value ? setpapervalue("MATTE") : ""
                        }
                        value="true"
                      />{" "}
                      Matte
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="input-radio off"
                        name="pilih"
                        onClick={(e) =>
                          e.target.value ? setpapervalue("GLOSS") : ""
                        }
                      />
                      Gloss
                    </label>
                  </div>
                </div>
                <div className="line-break" />
                <label className="ps-0">
                  <strong className="text-dark">Frame Material</strong>
                </label>
                <br />
                <div className="card3">
                  <div className="form">
                    <label>
                      <input
                        type="radio"
                        className="input-radio1 off"
                        defaultChecked
                        name="frameMaterialChoice"
                        onClick={() => setframematerial("CLASSIC")}
                      />{" "}
                      Classic
                    </label>
                    <label>
                      <input
                        type="radio"
                        className="input-radio1 on"
                        name="frameMaterialChoice"
                        onClick={() => setframematerial("MODERN")}
                      />
                      Modern
                    </label>
                  </div>
                </div>

                {/* <div className='line-break'/>
            
                  <div className='row'>
                    <div className='col-6'>
                    <label className="ps-0"><strong className="text-dark">Size </strong></label>
                    </div>
                    
                    <div className='col-6'>
                    <span className="pe-0 text-end">
                    <p className="price">{uploaded_images.length?framepricedata? handlerprice()?handlerprice().split('-')[1] :null:null:null}</p>
                  </span>
                    </div>
                  </div> */}
                <div className="line-break" />

                <label className="ps-0 mb-2">
                  <strong className="text-dark">Frame Size</strong>
                </label>
                <br />
                <div className="form-select-wrapper">
                  <select
                    required
                    onChange={(e) => setpricelist(e.target.value)}
                    className="form-select form-select-md "
                  >
                    <option value="" hidden>
                      select size
                    </option>
                    {handlerprice()
                      ? handlerprice()
                          .split(",")
                          .map((itm, k) => (
                            <option key={k} value={itm}>
                              {itm.split("-")[1]} &emsp;&emsp;&emsp;&emsp;
                              {itm.split("-")[2]} AED{" "}
                            </option>
                          ))
                      : null}
                    {/* <option value={"45 x 30 cm"} >45 x 30 cm &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; AED 150</option>
                    <option value={"45 x 30 cm"} >45 x 30 cm &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; AED 150</option> */}
                  </select>
                </div>

                {/* <div className='row'>
                    <div className='col-6'>
                    <label className="ps-0"><strong className="text-dark">Price </strong></label>
                    </div>
                    <div className='col-6'>
                    <span className="pe-0 text-end">
                    <p className="price">{uploaded_images.length?framepricedata? handlerprice()?handlerprice().split('-')[2] :null:null:null}<span className='aedsize'> AED</span></p>
                  </span>
                    </div>
                  </div>
                   */}
              </div>
              <div className="container">
                <button
                  onClick={() =>
                    selectitm
                      ? pricelist
                        ? addtocart(pricelist)
                        : notifyerror("select size")
                      : notifyerror("Select Frame")
                  }
                  className="btn btn-primary rounded w-100 mt-0"
                  style={{
                    backgroundColor: "#3e4192",
                    borderColor: "#3e4192",
                    fontWeight: 500,
                  }}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
          {/* <div className=' col-12 col-md-4 col-lg-4'>
        <div className='p-2 my-5 border-cart 'style={uploaded_images.length?{display:"block"}:{display:'none'}} >
          
          <div className=" p-2">
          <div><h3 className=" pb-1 ">Mini Frames</h3></div>
          <div className='line-break'/>
                  <label className="ps-0"><strong className="text-dark">Papper</strong></label><br/>              
                 <div className="switch-field ">
                  <input type="radio" id="radio-one" name="switch-one" onClick={(e)=>e.target.value?setpapervalue("MATTE"):""} value="true" defaultChecked/>
                  <label className='label1' htmlFor="radio-one">MATTE</label>
                  <input type="radio" id="radio-two" name="switch-one" onClick={(e)=>e.target.value?setpapervalue("GLOSS"):""} defaultValue="GLOSS" />
                  <label className='label2' htmlFor="radio-two">GLOSS</label>
                </div>
              
                <div className='line-break'/>
                  <label className="ps-0"><strong className="text-dark">Frame Material</strong></label><br/>              
                 <div className="switch-field2 ">
                  <input   defaultValue="CLASSIC" />
                  <label className='label1' onClick={()=>setframematerial("CLASSIC")}  style={framematerial==="CLASSIC"?{backgroundColor: "#111111",boxShadow: "none", color: "white"}:{}} >CLASSIC LOOK</label>
                  <input     defaultValue="MODERN" />
                  <label className='label2' onClick={()=>setframematerial("MODERN")} style={framematerial==="MODERN"?{backgroundColor: "#111111",boxShadow: "none", color: "white"}:{}} >MODERN LOOK</label>
                </div>
              
                <div className='line-break'/>
            <div className='mb-3'>
              <label className='ps-0 mb-2'><strong className='text-dark'>{frame} Frame</strong></label><br/>
              <div className='d-flex table-responsive pb-1'>
                {framedata.map((itm,k)=>(
                  <div key={k} className='ps-2'>
                  <img className='frameimage ' style={frame===itm.framename?{border:"2px solid black"}:{}} onClick={()=>setframe(itm.framename) & setselectitm(itm)} src={itm.main_image} width={70} height={47} alt="img" />
                 </div>
                ))}              
              </div>
            </div> 
                <div className='line-break'/>
                  <div className='row'>
                    <div className='col-8'>
                    <label className="ps-0"><strong className="text-dark">Size</strong></label>
                    </div>
                    <div className='col-4'>
                    <span className="pe-0 text-start">
                    <p className="price">{uploaded_images.length? framepricedata? handlerprice().split('-')[1] :null:null}</p>
                  </span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                    <label className="ps-0"><strong className="text-dark">Price for {uploaded_images.length}</strong></label>
                    </div>
                    <div className='col-4'>
                    <span className="pe-0 text-start">
                    <p className="price">{uploaded_images.length?framepricedata? handlerprice().split('-')[2] * uploaded_images.length:null:null}<span className='aedsize'>AED</span></p>
                  </span>
                    </div>
                  </div>
                  
          </div>
          <div className='container'><button  onClick={()=>selectitm? addtocart(handlerprice()):notifyerror("Select Frame")} className="btn btn-primary rounded w-100 ">ADD TO CART</button></div>

          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
