import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function AboutUs2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div
        className="container mt-15 pt-5"
        style={{ position: "relative", width: "100%" }}
      >
        <div className="card-body about-container mx-5">
          <h1 className="display-4 mb-2">Online Printing & Framing Services</h1>
          <h2 className="display-5 mb-7" style={{ fontWeight: 500 }}>
            Stylish Photo Frames Online
          </h2>
          <p>
            Discover the magic of the best online printing and framing at Foto
            Frames UAE! We are a group of art enthusiasts who can help you add
            the ideal frame to your invaluable artwork and photographs. With a
            vast collection of trendy and premium-quality photo frames online,
            we offer the best choices that suit your preferences.
          </p>

          <p>
            If you are in search of the best online printing and framing in
            Dubai then look no further!
          </p>

          <p>
            Our expert team ensures that each product is made with top-notch
            quality that exceeds expectations. From start to finish, we combine
            convenience with style, assuring you the best picture printing
            experience in UAE.
          </p>

          <p>
            Visit our frame shop in Dubai for an exclusive selection of
            high-quality frames
          </p>
        </div>

        <div className="d-flex about-img-container mx-4">
          <div className="about-floating">
            <img src="\assets\img\photos\abt-img-2.jpg" />
            <p>Elagance in every printed frame.</p>
          </div>
          <div className="about-floating">
            <img src="\assets\img\photos\abt-img-1.jpg" />
            <p>Print and frame your memories with style.</p>
          </div>
          <div className="about-floating">
            <img src="\assets\img\photos\abt-img-3.jpg" />
            <p>Crafting frames for your precious photo prints.</p>
          </div>
        </div>

        <div className="card-body about-p-wrapper mx-5">
          <p>Because every printed photo deserves a beautiful frame !</p>
        </div>
      </div>

      <Helmet>
        <title>
          FotoFrame: Dubai's Top Online Photo Printing & Framing Shop
        </title>
        <meta
          name="description"
          content="FotoFrame, Dubai's premier online printing &framing shop, transforms your beloved images into stunning visual masterpieces. Frame your world with us."
        />
        <meta
          name="keywords"
          content="Online Printing & Framing, photo frame online"
        />
        <meta property="og:url" content="https://fotoframesuae.com/about-us" />
        <meta
          property="og:title"
          content="FotoFrame: Dubai's Top Online Photo Printing & Framing Shop"
        />
        <meta
          property="og:description"
          content="FotoFrame, Dubai's premier online printing &framing shop, transforms your beloved images into stunning visual masterpieces. Frame your world with us."
        />
        <link rel="canonical" href="https://fotoframesuae.com/about-us" />
      </Helmet>
    </div>
  );
}
