import React, { useEffect, useState } from "react";
// import { Carousel } from 'primereact/carousel';
// import 'primereact/resources/primereact.min.css';
// import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./CustomCarousal.css";
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { BsChevronRight } from "react-icons/bs";
import Callaxios from "../../Component.js/Callaxios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Blogs() {
  let navigate = useNavigate();
  const [blogs, setblogs] = useState([]);

  useEffect(() => {
    getBlogs();
    window.scrollTo(0, 0);
  }, []);

  // const blogs =[
  //     "/assets/img/photos/pf-img-1.jpg",
  //     "/assets/img/photos/pf-img-2.jpg",
  //     "/assets/img/photos/pf-img-3.jpg",
  //     "/assets/img/photos/pf-img-1.jpg",
  //     "/assets/img/photos/pf-img-2.jpg",
  //     "/assets/img/photos/pf-img-3.jpg"
  // ]

  // const notify = (msg) =>
  //     toast.success(msg, {
  //       position: "top-left",
  //       theme: "dark",
  //     });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const getBlogs = async () => {
    try {
      let data = await Callaxios("get", "blogs/blog/");
      if (data.status === 200) {
        setblogs(data.data ?? []);
      }
    } catch (error) {
      console.error(error);
      notifyerror(error);
    }
  };

  // const responsiveOptions = [
  //     {
  //         breakpoint: '1024px',
  //         numVisible: 3,
  //         numScroll: 1
  //     },
  //     {
  //         breakpoint: '768px',
  //         numVisible: 2,
  //         numScroll: 1
  //     },
  //     {
  //         breakpoint: '560px',
  //         numVisible: 1,
  //         numScroll: 1
  //     },
  // ];

  // const itemTemplate = (item) => {
  //     return (
  //         <div className="b-carousel-item">
  //             <div className="b-img-wrapper">
  //             <img src={item} alt="Blog image" />
  //             </div>
  //             <h2>Blog Title</h2>
  //             <a href="#">Read more</a>
  //         </div>
  //     );
  // };
  return (
    <div>
      <div
        className="container mt-15 pt-5"
        style={{ position: "relative", width: "100%" }}
      >
        <div className="card-body mx-4" style={{ padding: "50px 5px" }}>
          <h1
            className="display-4 mb-7"
            style={{ fontSize: "32px", textAlign: "center", fontWeight: 600 }}
          >
            OUR BLOG
          </h1>

          {/* <div className="swiper gallery-slider">
    <Swiper 
    spaceBetween={0}
                        slidesPerView={1}
                        pagination={{ clickable: true, dynamicBullets: true }}
                        breakpoints={{
                            1024: { slidesPerView: 3 },
                            768: { slidesPerView: 2 },
                            560: { slidesPerView: 1 }
                        }}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        loop={true}
                        modules={[Pagination, Autoplay]}
                        onSwiper={(swiper) => {
                            document.documentElement.style.setProperty('--swiper-pagination-bullets', swiper.slides.length);
                        }}
                        >
{blogs.map((blog, index) => (
        <SwiperSlide key={index}>
             <div className="img-item">
                                    <img src={blog?.image} alt={`image${index + 1}`} 
                                    onError={(e) => {
                                                e.target.src =
                                                  "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png ";
                                              }}/>
                                    <div className="caption">
                                        <h3>{blog?.blog_name ?? ""}</h3>
                                        <button type="button" className='btn btn-primaryog read-more' onClick={()=> navigate(`/blog-details/${blog.id}`)}>
        Read more <BsChevronRight style={{ marginLeft: '10px', marginRight: '-9px' }} />
      </button>
                                    </div>
                                </div>
        </SwiperSlide>
))}
    </Swiper>
    </div> */}

          <div className="row">
            {blogs.map((blog, index) => (
              <div className="col-12 col-md-6 col-lg-4 mb-6" key={index}>
                <div className="blog-card">
                  <div className="blog-card-img-wrapper">
                    <img
                      src={blog?.image}
                      alt="img"
                      onError={(e) => {
                        e.target.src =
                          "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png ";
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="caption" style={{ margin: "0 20px" }}>
                    <h3 className="mb-2">{blog?.blog_name ?? ""}</h3>
                    <button
                      type="button"
                      className="btn btn-primaryog read-more"
                      onClick={() => navigate(`/blog-details/${blog.id}`)}
                    >
                      Read more{" "}
                      <BsChevronRight
                        style={{ marginLeft: "10px", marginRight: "-9px" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
