import React, { useEffect } from "react";
import { FaUpload } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function CollageSquareFrames() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="container mt-15 pt-8 mb-5">
        <div className="card-body mx-4 frames-card-body">
          <h4 className="display-5 mb-12 section-title-main">
            USE ONE OF OURS
          </h4>

          <div className="square-categories">
            <Link to={{ pathname: "/square-collage" }} state={{ number: 2 }}>
              <div className="square-types">
                <div className="text-center upload_card ">
                  <FaUpload size={50} color={"black"} />
                  <h6>upload images</h6>
                </div>
                <p className="mt-2">2x2</p>
              </div>
            </Link>
            <Link to={{ pathname: "/square-collage" }} state={{ number: 3 }}>
              <div className="square-types">
                <div className="text-center upload_card ">
                  <FaUpload size={50} color={"black"} />
                  <h6>upload images</h6>
                </div>
                <p className="mt-2">3x3</p>
              </div>
            </Link>
            <Link to={{ pathname: "/square-collage" }} state={{ number: 4 }}>
              <div className="square-types">
                <div className="text-center upload_card ">
                  <FaUpload size={50} color={"black"} />
                  <h6>upload images</h6>
                </div>
                <p className="mt-2">4x4</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
