import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaPinterest,
  FaRegEnvelope,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer2() {
  return (
    <>
      <div>
        <footer className="footer-bg">
          <div
            className="container py-10 py-md-10"
            style={{ paddingLeft: 0, maxWidth: "100%" }}
          >
            <div className="row gy-6 gy-lg-0 flex-column flex-sm-row">
              <div className="one col-12 col-sm-5">
                <div className="col-md-12 col-lg-12">
                  <div className="widget logo-area row flex-nowrap">
                    <div className="col-6 col-md-5">
                      <img
                        src="\assets\img\photos\fotoframe-logo2.png"
                        alt="img"
                        style={{
                          width: "140px",
                          height: "auto",
                        }}
                        className="footer-logo"
                      />
                    </div>
                    <div
                      className="col-6 col-md-6 d-flex align-items-center "
                      style={{ paddingLeft: 0 }}
                    >
                      <span className="logo-text">
                        Where Memories Meet Artistry!
                      </span>
                    </div>
                  </div>

                  <nav
                    className="nav social"
                    style={{ marginLeft: "60px", marginTop: "20px" }}
                  >
                    <a href="https://www.pinterest.com/fotoframesuae/" target="_blank">
                      <FaPinterest size={25} color="#fff" />
                    </a>
                    <a href="https://www.facebook.com/Fotoframesdubai/" target="_blank">
                      <FaFacebookF size={25} color="#fff" />
                    </a>
                    <a href="https://www.instagram.com/fotoframesuae?igsh=MWptdnRxdTVwZnBmeQ==" target="_blank">
                      <FaInstagram size={25} color="#fff" />
                    </a>
                  </nav>
                </div>
              </div>

              <div className="two row col-12 col-sm-7 flex-column flex-sm-row space-evenly">
                {/* /column */}
                <div className="col-12 col-sm-4">
                  <div className="widget">
                    <h4 className="widget-title mb-3 get-in-margin">
                      Get in Touch
                    </h4>
                    <address className="get-in-margin mb-3">
                      Dubai,UAE,al-qusais manzil bldg
                    </address>

                    <span
                      className="footer-span"
                      // data-cfemail="aac3c4ccc5eacfc7cbc3c684c9c5c7"
                    >
                      <a href="mailto:fotoframe@gmail.com">
                        <FaRegEnvelope style={{ marginRight: "10px" }} />
                        fotoframe@gmail.com
                      </a>
                    </span>
                    <br />
                    <span
                      className="footer-span"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <a href="tel:04-451 9623">
                        <FaPhoneAlt
                          style={{ marginRight: "7px", fontSize: "14px" }}
                        />{" "}
                        04-451 9623
                      </a>
                    </span>
                  </div>
                  {/* /.widget */}
                </div>
                {/* /column */}
                <div className="col-12 col-sm-4 learn-more">
                  <div className="widget">
                    <h4 className="widget-title  mb-3">Learn More</h4>
                    <ul className="list-unstyled text-reset mb-0">
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>

                      <li>
                        <Link to="/terms">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                  {/* /.widget */}
                </div>

                <div className="col-12 col-sm-4">
                  <div className="widget">
                    <h4 className="widget-title  mb-3">Frames</h4>
                    <div className="widget">
                      <ul className="list-unstyled text-reset mb-0">
                        <li>
                          <Link to="/mini-frame">Wall Frame</Link>
                        </li>
                        <li>
                          <Link to="/print-frame">Frame</Link>
                        </li>
                        <li>
                          <Link to="/collage-frame">Collage Frame</Link>
                        </li>
                        <li>
                          <Link to="/canvas-frame">Canvas</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="horizontal-line" />

            <div className="widget copyright-text">
              <span className="footer-span">
                © 2023 FotoFrame.All rights reserved.
              </span>
            </div>
          </div>
        </footer>
        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      </div>
    </>
  );
}
