import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function CanvasFrame2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="container mt-15 pt-8">
        <div className="card-body mx-md-5 mx-3 frames-card-body">
          <h4 className="display-5 mb-15 section-title-main">
            USE ONE OF OURS
          </h4>

          <div className="frame-categories">
            <Link
              to={{ pathname: "/upload-canvas" }}
              state={{ string: "landscape" }}
            >
              <div className="main-image-container fimg-landscape">
                <img src="\assets\img\photos\image-1.png" />
                <p>Landscape</p>
              </div>
            </Link>
            <Link
              to={{ pathname: "/upload-canvas" }}
              state={{ string: "portait" }}
            >
              <div className="main-image-container fimg-portrait">
                <img src="\assets\img\photos\image-1.png" />
                <p>Portrait</p>
              </div>
            </Link>
            <Link
              to={{ pathname: "/upload-canvas" }}
              state={{ string: "square" }}
            >
              <div className="main-image-container fimg-square">
                <img src="\assets\img\photos\image-1.png" />
                <p>Square</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="row mx-md-5 mx-3 py-8">
          <h2 className="display-4 p-0 main-frame-title">
            CANVAS PHOTO
            <br />
            PRINTS & FRAMES
          </h2>

          <div className="frameimg-container col-lg-4 col-md-6 col-12">
            <div className="main-fimg-bg">
              <div className="main-fimg-container">
                <img src="\assets\img\photos\can-img-2.jpeg" alt="img" />
              </div>
            </div>
          </div>
          <div className="framecon-container col-lg-8 col-md-6 col-12">
            <div className="framecon-mt">
              <h1 className="display-4 mb-6 mb-lg-8 mb-xl-11 mb-md-6 px-lg-14 main-frame-subtitle">
                Create Stunning Canvas Photo Prints And Frames In Dubai
              </h1>

              <div className="main-fpara-container">
                <p>
                  The photo frames and canvas prints make a unique work of art
                  for you, your loved ones, and your friends. Our Collage photo
                  frame shop in UAE brings you frames and images on canvas that
                  serve as eye-catching additions to your house.
                </p>

                <p>
                  Carefully made with premium materials, our poster printing
                  service in Dubai guarantees quality products and brilliant
                  colors that will make your pictures come to life. You can
                  alter your best photos with our customized canvas printing
                  service in Dubai.
                </p>

                <p>
                  Whether it's breathtaking scenery, a family photo, or a
                  momentous occasion captivated in time, our canvas picture
                  prints and poster printing in Dubai will add a touch of
                  sophistication to any spot. To match your decor and style, we
                  bring you a wide variety of frames with different sizes and
                  customizations.
                </p>

                <p>
                  So, no need to worry about Where to get pictures printed in
                  UAE with high quality and affordable prices. Our canvas prints
                  provide customized presents that can be appreciated for years
                  to come.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main-fimgs-wrapper d-flex flex-row mx-md-5 mx-3 mb-15">
          <div className="main-fimgs">
            <img src="\assets\img\photos\can-img-3.jpg" />
          </div>
          <div className="main-fimgs">
            <img src="\assets\img\photos\can-img-4.jpg" />
          </div>
          <div className="main-fimgs">
            <img src="\assets\img\photos\can-img-5.jpg" />
          </div>
        </div>
      </div>

      <Helmet>
        <title>
          High-Quality Canvas Prints and Frames | Canvas Printing Dubai
        </title>
        <meta
          name="description"
          content="Transform your photos into stunning canvas prints with our canvas printing Dubai service. Choose from various sizes for vibrant canvas picture prints."
        />
        <meta
          name="keywords"
          content="canvas prints, canvas printing dubai  |  canvas picture prints"
        />
        <meta
          property="og:url"
          content="https://fotoframesuae.com/canvas-frame"
        />
        <meta
          property="og:title"
          content="High-Quality Canvas Prints and Frames | Canvas Printing Dubai"
        />
        <meta
          property="og:description"
          content="Transform your photos into stunning canvas prints with our canvas printing Dubai service. Choose from various sizes for vibrant canvas picture prints."
        />
        <link rel="canonical" href="https://fotoframesuae.com/canvas-frame" />
      </Helmet>
    </div>
  );
}
