import React, { useEffect } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { Link, useNavigate } from "react-router-dom";
import Scripts from "../../Component.js/Scripts";
import GuideArea from "./GuideArea";
import { BsChevronRight } from "react-icons/bs";
import Ticker from "../Components/Ticker";
import ServiceHighlights from "./ServiceHighlights";
import FotoFrameOverview from "./FotoFrameOverview";
import BenefitsSection from "./BenefitsSection";
import PromoSignUpSection from "./PromoSignUpSection";
import { Carousel } from "react-bootstrap";
import BlogsCarousal from "./BlogsCarousal";
import FAQ from "./FAQ";
import { Helmet } from "react-helmet";

export default function Home2() {
  useEffect(() => {
    Scripts();
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <section className="wrapper">
        <div className="pt-2">
          <div className="home-hero">
            <div className="home-hero-overlay"></div>
            <div className="home-text-container home-hero-content">
              <div className="home-text-1">
                <h1 className="mb-0">
                  Custom Photo Printing & Framing in Dubai
                </h1>
              </div>
              <div className="home-text-2">
                <h2 className="mb-0">
                  Framing Life's Best Shots: Where Memories Meet Artistry!
                </h2>
              </div>
              <div className="mt-2">
                <button
                  className="btn btn-primaryog home-hero-button"
                  onClick={() => navigate("/frames")}
                >
                  Frame Here{" "}
                  <BsChevronRight
                    style={{ marginLeft: "20px", marginRight: "-9px" }}
                  />
                </button>
              </div>
            </div>
          </div>

          <Ticker />
        </div>
      </section>
      <GuideArea />
      {/*/.row */}
      <section className="wrapper container">
        <div className="pt-13 pt-md-14 pt-lg-15">
          <ServiceHighlights />
          {/*/.row */}
        </div>
      </section>

      <FotoFrameOverview />
      {/*/.row */}

      <section className="wrapper container">
        <BenefitsSection />
      </section>

      <PromoSignUpSection />

      <BlogsCarousal />

      <FAQ />

      {/* /section */}
      {/* <section className="wrapper bg-soft-primary">
      <div className="container py-14 py-md-17">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-lg-7">
            <figure><img className="w-auto" src="assets/img/illustrations/i5.png"  alt="img"/></figure>
          </div>
          <div className="col-lg-5">
            <h3 className="display-4 mb-7">Got any questions? Don't hesitate to get in touch.</h3>
            <div className="d-flex flex-row">
              <div>
                <div className="icon text-primary fs-28 me-4 mt-n1"> <SlLocationPin/> </div>
              </div>
              <div>
                <h5 className="mb-1">Address</h5>
                <address>Dubai , UAE , Al Qusais Manzil bldg</address>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <div className="icon text-primary fs-28 me-4 mt-n1"> <FiPhoneCall/> </div>
              </div>
              <div>
                <h5 className="mb-1">Phone</h5>
                <p>04-451 9623</p>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <div className="icon text-primary fs-28 me-4 mt-n1"> <MdOutlineEmail/> </div>
              </div>
              <div>
                <h5 className="mb-1">E-mail</h5>
                <p className="mb-0"><a href="mailto:email@example.com">fotoframe@gmail.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

      <Helmet>
        <title>
          Personalized Photo Printing and Framing Online | FotoFrame
        </title>
        <meta
          name="description"
          content="Frame memories as art!. Enjoy gallery-quality photo printing and framing, with prints, frames, canvases, and collages delivered across the UAE."
        />
        <meta
          name="keywords"
          content="photo printing and framing, print and frame photos"
        />
        <meta property="og:url" content="https://fotoframesuae.com/" />
        <meta
          property="og:title"
          content="Personalized Photo Printing and Framing Online | FotoFrame"
        />
        <meta
          property="og:description"
          content="Frame memories as art!. Enjoy gallery-quality photo printing and framing, with prints, frames, canvases, and collages delivered across the UAE."
        />
        <link rel="canonical" href="https://fotoframesuae.com/" />
      </Helmet>
    </div>
  );
}
