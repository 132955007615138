import React from "react";

export default function BenefitsSection() {
  return (
    <>
      <div className="row gx-md-5 gy-5 mx-0 mx-md-2">
        <div className="col-md-12 col-xl-12 text-center benefits-title-col">
          <h3 className="display-4 benefits-title mb-md-6 mb-sm-13 mb-5 mb-lg-6">
            WHY CHOOSE US ?
          </h3>
        </div>

        <div className="col-md-4 col-xl-4 benefits-col-1">
          <div className="benefits-card shadow-lg position-relative">
            <h2 className="benefits-card-title b-title-1">Customization</h2>
            <p className="benefits-card-paragraph">
              We customize the frame according to the taste of the customers,
              which allows you to choose from different styles, colors, and
              sizes.
            </p>

            <div className="benefits-image-wrapper">
              <img src="\assets\img\photos\Bframe-4.jpg" alt="img" />
            </div>

            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        <div className="col-md-4 col-xl-4 benefits-col-2">
          <div className="benefits-card shadow-lg position-relative">
            <h2 className="benefits-card-title b-title-2">Affordability</h2>
            <p className="benefits-card-paragraph">
              Our photo frames cater to the budget of every customer. We offer
              photo framing services at a reasonable rate to fit any budget.
            </p>
            <div className="benefits-image-wrapper">
              <img src="\assets\img\photos\Bframe-5.jpeg" alt="img" />
            </div>

            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        {/*/column */}
        <div className="col-md-4 col-xl-4 benefits-col-3">
          <div className="benefits-card shadow-lg position-relative">
            <h2 className="benefits-card-title b-title-3">
              Safe and
              <br /> Secure Delivery
            </h2>
            <p className="benefits-card-paragraph">
              Our top priority is to deliver your frames fast and securely so
              they may be displayed in perfect condition.
            </p>

            <div className="benefits-image-wrapper">
              <img src="\assets\img\photos\Bframe-6.jpeg" alt="img" />
            </div>

            {/*/.card-body */}
          </div>
          {/*/.card */}
        </div>
        {/*/column */}
      </div>
    </>
  );
}
