import React, { useContext, useEffect, useState } from "react";
import { Simplecontext } from "./Simplecontext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Callaxios from "./Callaxios";
import { BaseUrl } from "./Url";
export default function Signmodal() {
  const { promodal1, promodal2, setpromodal1, setpromodal2 } =
    useContext(Simplecontext);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [firstname, setfirstname] = useState("");
  const [signusername, setsignusername] = useState("");
  const [signpassword, setsignpassword] = useState("");
  const [signrepassword, setsignrepassword] = useState("");
  const [forgotpass, setforgotpass] = useState(false);
  const [loading, setloading] = useState({ forgot: false, login: false });
  let navigate = useNavigate();
  useEffect(() => {}, []);
  const notify = (msg) =>
    toast.success(msg, {
      position: "top-left",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const login = async (e) => {
    e.preventDefault();
    setloading({ ...loading, login: true });
    try {
      let body = {
        method: "post",
        url: BaseUrl + "user/login/",
        data: { username: username, password: password },
      };

      let data = await axios(body);
      setloading({ ...loading, login: false });
      if (data.data.Status === 200) {
        window.localStorage.setItem("fotoframe_usertoken", data.data.token);
        setpromodal1(false);
        setpromodal2(false);
        setsignnull();
        return navigate("/profile");
      } else {
        notifyerror("Invalid Username or password");
      }
    } catch (error) {
      console.error(error);
      notifyerror("Invalid Username or password");
    }
    setloading({ ...loading, login: false });
  };
  const forgotHandler = async (e) => {
    e.preventDefault();
    setloading({ ...loading, forgot: true });
    try {
      let body = {
        method: "post",
        url: BaseUrl + "user/sentmail/",
        data: {
          email: username,
          url: window.location.origin + "/forgotpassword/",
        },
      };
      let data = await axios(body);
      setloading({ ...loading, forgot: false });
      // console.log("data",data)
      if (data.data.Status === 200) {
        notify("check your mail to change password");
        // window.localStorage.setItem("fotoframe_usertoken",data.data.token)
        // setpromodal1(false)
        // setpromodal2(false)
        setforgotpass(false);
        setsignnull();
        // return navigate("/profile")
      } else {
        notifyerror(data.data.Message);
      }
    } catch (error) {
      console.error(error);
      notifyerror("Invalid Username or password");
    }
    setloading({ ...loading, forgot: false });
  };
  const setsignnull = () => {
    setfirstname("");
    setusername("");
    setsignpassword("");
    setsignrepassword("");
    setsignusername("");
    setpassword("");
  };
  const postuser = async (e) => {
    e.preventDefault();
    try {
      if (signpassword === signrepassword) {
        let data = await Callaxios("post", "user/user/", {
          username: signusername,
          first_name: firstname,
          password: signpassword,
        });
        if (data.data.Status === 200) {
          notify("Successfully registered");
          setpromodal2(false);
          setpromodal1(true);
          setsignnull();
        } else {
          let msg = "some thing went wrong ";
          if (data?.data?.Message) {
            msg = data.data.Message;

            const start_pos =
              data.data.Message.indexOf("string='") + "string='".length;
            const end_pos = data.data.Message.indexOf("'", start_pos);
            if (start_pos !== -1 && end_pos !== -1) {
              let extractedMessage = data.data.Message.substring(
                start_pos,
                end_pos
              );
              // console.log(extractedMessage);
              msg = extractedMessage;
            }
          }

          notifyerror(msg);
        }
      } else {
        notifyerror("Please provide same password");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const profilehandler = () => {
    if (window.localStorage.getItem("fotoframe_usertoken")) {
      return navigate("/profile");
    } else {
      setpromodal1(true);
    }
  };
  return (
    <div>
      <ToastContainer />
      <div
        className={promodal1 ? "modal fade show spinner-container" : ""}
        style={promodal1 ? { display: "block" } : { display: "none" }}
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button
                type="button"
                onClick={() => setpromodal1(false) & setsignnull()}
                className="btn-close"
                style={{ color: "#3e4192" }}
              />
              {forgotpass ? (
                <>
                  <h2 className="mb-3 text-start">Forgot password!</h2>
                  <p className="lead mb-6 text-start">Fill your email .</p>
                  <form onSubmit={(e) => forgotHandler(e)} className="mb-3">
                    <div className="form-floating mb-4">
                      <input
                        required
                        onChange={(e) =>
                          !/\s/.test(e.target.value)
                            ? setusername(e.target.value)
                            : ""
                        }
                        value={username}
                        type="emaillogin"
                        className="form-control"
                        placeholder="Email"
                      />
                      <label htmlFor="loginEmail">Email</label>
                    </div>

                    <button
                      style={{ background: "#3e4192", borderColor: "#3e4192" }}
                      type={loading.forgot ? "button" : "submit"}
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                    >
                      {loading.forgot ? "Loading..." : "Sent email"}{" "}
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <h2 className="mb-3 text-start">
                    {promodal1 ? "Sign In to Order" : "Welcome Back"}
                  </h2>
                  <p className="lead mb-6 text-start">
                    Fill your email and password to sign in.
                  </p>
                  <form onSubmit={(e) => login(e)} className="mb-3">
                    <div className="form-floating mb-4">
                      <input
                        required
                        onChange={(e) =>
                          !/\s/.test(e.target.value)
                            ? setusername(e.target.value)
                            : ""
                        }
                        value={username}
                        type="emaillogin"
                        className="form-control"
                        placeholder="Email"
                      />
                      <label htmlFor="loginEmail">Email</label>
                    </div>
                    <div className="form-floating  mb-4">
                      <input
                        required
                        onChange={(e) => setpassword(e.target.value)}
                        value={password}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      {/* <span className="password-toggle"><i className="uil uil-eye" /></span> */}
                      <label htmlFor="loginPassword">Password</label>
                    </div>
                    <button
                      style={{ background: "#3e4192", borderColor: "#3e4192" }}
                      type={loading.login ? "button" : "submit"}
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                    >
                      {loading.login ? "Loading..." : "Login"}
                    </button>
                  </form>
                </>
              )}
              {/* /form */}
              {forgotpass ? (
                <p className="mb-1">
                  <a
                    onClick={() => setforgotpass(false)}
                    href="#"
                    className="hover"
                  >
                    Sign in{" "}
                  </a>
                </p>
              ) : (
                <p className="mb-1">
                  <a
                    onClick={() => setforgotpass(true)}
                    href="#"
                    className="hover"
                  >
                    Forgot Password?
                  </a>
                </p>
              )}

              <p className="mb-0">
                Don't have an account?{" "}
                <a
                  href="#"
                  onClick={() =>
                    setpromodal1(false) & setpromodal2(true) & setsignnull()
                  }
                  className="hover"
                >
                  Sign up
                </a>
              </p>

              {/*/.social */}
            </div>
            {/*/.modal-content */}
          </div>
          {/*/.modal-body */}
        </div>
        {/*/.modal-dialog */}
      </div>
      {/*/.modal */}
      <div
        className={promodal2 ? "modal fade show spinner-container" : ""}
        style={promodal2 ? { display: "block" } : { display: "none" }}
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button
                type="button"
                onClick={() => setpromodal2(!promodal2)}
                className="btn-close"
                style={{ color: "#3e4192" }}
              />

              <h2 className="mb-3 text-start">Sign up to FotoFrame</h2>
              <p className="lead mb-6 text-start">
                Registration takes less than a minute.
              </p>
              <form onSubmit={(e) => postuser(e)} className="text-start mb-3">
                <div className="form-floating mb-4">
                  <input
                    required
                    // onChange={(e) => setfirstname(e.target.value)}
                    onChange={(e) => {
                      let value = e.target.value;
                      // Prevent space as the first character
                      if (value.startsWith(" ")) {
                        value = value.trim();
                      }
                      setfirstname(value);
                    }}
                    value={firstname}
                    className="form-control"
                    placeholder="name"
                  />
                  <label htmlFor="">Name</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    required
                    onChange={(e) => setsignusername(e.target.value)}
                    value={signusername}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <label htmlFor="">Email</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    required
                    // onChange={(e) => setsignpassword(e.target.value)}
                    onChange={(e) => {
                      let value = e.target.value;
                      // Ensure password length is within bounds
                      if (value.length > 14) {
                        value = value.slice(0, 14);
                      }
                      setsignpassword(value);
                    }}
                    value={signpassword}
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    minLength="4"
                    maxLength="14"
                  />
                  {/* <span className="password-toggle"><i className="uil uil-eye" /></span> */}
                  <label htmlFor="">Password</label>
                </div>
                <div className="form-floating  mb-4">
                  <input
                    required
                    onChange={(e) => setsignrepassword(e.target.value)}
                    value={signrepassword}
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                  {/* <span className="password-toggle"><i className="uil uil-eye" /></span> */}
                  <label htmlFor="">Confirm Password</label>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  style={{ background: "#3e4192", borderColor: "#3e4192" }}
                >
                  Sign Up
                </button>
              </form>
              {/* /form */}
              <p className="mb-0">
                Already have an account?{" "}
                <a
                  href="#"
                  onClick={() => setpromodal2(false) & setpromodal1(true)}
                  className="hover"
                >
                  Sign in
                </a>
              </p>

              {/*/.social */}
            </div>
            {/*/.modal-content */}
          </div>
          {/*/.modal-body */}
        </div>
        {/*/.modal-dialog */}
      </div>
    </div>
  );
}
