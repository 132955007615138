import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function CollageFrame2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="container mt-15 pt-8">
        <div className="card-body mx-md-5 mx-3 frames-card-body">
          <h4 className="display-5 mb-15 section-title-main">
            USE ONE OF OURS
          </h4>

          <div className="frame-categories">
            <Link to="/landscape-collage">
              <div className="main-image-container fimg-landscape">
                <img src="\assets\img\photos\image-1.png" />
                <p>Landscape</p>
              </div>
            </Link>
            <Link to="/portrait-collage">
              <div className="main-image-container fimg-portrait">
                <img src="\assets\img\photos\image-1.png" />
                <p>Portrait</p>
              </div>
            </Link>
            <Link to="/square-frames">
              <div className="main-image-container fimg-square">
                <img src="\assets\img\photos\image-1.png" />
                <p>Square</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="row mx-md-5 mx-3 py-8">
          <h2 className="display-4 p-0 main-frame-title">
            COLLAGE PHOTO
            <br />
            PRINTS & FRAMES
          </h2>

          <div className="frameimg-container col-lg-4 col-md-6 col-12">
            <div className="main-fimg-bg">
              <div className="main-fimg-container">
                <img src="\assets\img\photos\cf-img-2.jpg" alt="img" />
              </div>
            </div>
          </div>
          <div className="framecon-container col-lg-8 col-md-6 col-12">
            <div className="framecon-cge-mt">
              <h1 className="display-4 mb-6 mb-lg-8 mb-xl-11 mb-md-6 px-lg-14 main-frame-subtitle">
                Create Memories with Collage Photo Frames And Poster Printing
              </h1>

              <div className="main-fpara-container">
                <p>
                  What makes your wedding, celebration, vacation, or an
                  unexpected day spent well with friends truly memorable? It's
                  the cherished collection of photographs taken on that day that
                  captures the essence of those special moments!
                </p>

                <p>
                  With Foto Frame's collage photo frames and poster printing
                  services in Dubai, we help you bring out the best in your most
                  treasured memories. Our collage photo frames allow you to
                  preserve the memories that are a part of who you are.
                </p>

                <p>
                  When it comes to framing multiple pictures showcasing an
                  event, a Collage photo frame is the best choice! With its
                  multiple slots for different picture sizes, the collage photo
                  frame lets you make a one-of-a-kind, customized display that
                  will catch the attention of everyone who sees it.
                </p>

                <p>
                  The collage photo frames we offer are classic, timeless pieces
                  that enhance the aesthetics of any space. This adaptable frame
                  is the perfect option to display family photographs, trip
                  photos, or creative designs. You can choose them as wall photo
                  frames or even consider them as a perfect gift for your loved
                  ones.
                </p>

                <p>
                  So, what's the wait for? Get your collage photo frames now and
                  let your memories shine brightly!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main-fimgs-wrapper d-flex flex-row mx-md-5 mx-3 mb-15">
          <div className="main-fimgs">
            <img src="\assets\img\photos\cf-img-3.jpg" />
          </div>
          <div className="main-fimgs">
            <img src="\assets\img\photos\cf-img-4.jpeg" />
          </div>
          <div className="main-fimgs">
            <img src="\assets\img\photos\cf-img-5.jpg" />
          </div>
        </div>
      </div>

      <Helmet>
        <title>Best Collage Photo Frames and Poster Printing in Dubai</title>
        <meta
          name="description"
          content="Capture your cherished memories with Fotoframe collage photo frames and poster printing in Dubai.High-quality, elegant frames perfect for home decor or gifting."
        />
        <meta
          name="keywords"
          content="Collage photo frame, poster printing dubai"
        />
        <meta
          property="og:url"
          content="https://fotoframesuae.com/collage-frame"
        />
        <meta
          property="og:title"
          content="Best Collage Photo Frames and Poster Printing in Dubai"
        />
        <meta
          property="og:description"
          content="Capture your cherished memories with Fotoframe collage photo frames and poster printing in Dubai.High-quality, elegant frames perfect for home decor or gifting."
        />
        <link rel="canonical" href="https://fotoframesuae.com/collage-frame" />
      </Helmet>
    </div>
  );
}
