import React, { useState } from "react";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faq = [
    {
      question: "What products do you offer?",
      answer:
        "We provide picture printing and framing. We also customize frames. Other services include photo printing and picture collage posters.",
    },
    {
      question: "How to upload photos?",
      answer: `Firstly, click on a product of your choice. You can select one of our collage options or frame a single picture. click on the ${"Upload Your Photo"} button on our homepage, select your image file from your device, and follow the prompts to customize your frame.`,
    },
    {
      question: "What photo resolution should I use while uploading?",
      answer:
        "The photo frame will look better if the images are at a higher resolution. So, uploading clear and higher-resolution photos would be perfect for display.",
    },
    {
      question: "What is the estimated delivery time for my order?",
      answer:
        "Processing typically takes 5-7 business days, and delivery times vary based on location. Upon making a purchase, you will receive an estimated delivery date.",
    },
    {
      question: "What is your return policy?",
      answer:
        "We want you to be completely satisfied with your purchase. If you are not happy with your frame, please contact us within 14 days of receiving your order to arrange a return or exchange.",
    },
  ];

  return (
    <>
      <section className="wrapper">
        <div className="container pb-12 pb-md-12">
          <div className="row gx-md-5 gy-5 mx-0 mx-md-4 mx-lg-12">
            <div className="col-md-12 col-xl-12 text-center">
              <h6
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: 400 }}
              >
                Need Help?
              </h6>
              <h3
                className="display-4 mb-4"
                style={{ fontSize: "24px", fontWeight: 600 }}
              >
                FREQUENTLY ASKED QUESTION
              </h3>
            </div>
            <div className="col-md-12 col-xl-12 px-lg-16">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                {faq.map((item, index) => (
                  <div class="accordion-item mb-2" key={index}>
                    <h2
                      class="accordion-header mb-0"
                      id={`flush-heading${index}`}
                    >
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded={activeIndex === index}
                        aria-controls={`flush-collapse${index}`}
                        style={{
                          padding: "7px 30px",
                          width: "100%",
                          border: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          background: "#3e41920f",
                        }}
                        onClick={() => handleToggle(index)}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#3e4192",
                            textAlign: "left",
                          }}
                        >
                          {item?.question}
                        </span>
                        <span style={{ marginBottom: "5px" }}>
                          {activeIndex === index ? (
                            <BsDashLg
                              size={25}
                              strokeWidth={1}
                              color="#3e4192"
                            />
                          ) : (
                            <BsPlusLg
                              size={25}
                              strokeWidth={1}
                              color="#3e4192"
                            />
                          )}
                        </span>
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className={`accordion-collapse collapse ${
                        activeIndex === index ? "show" : ""
                      }`}
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                      style={{
                        padding: "10px 30px 20px 30px",
                        background: "#3e41920f",
                      }}
                    >
                      <div class="accordion-body">
                        <p style={{ marginBottom: 0, fontWeight: 300 }}>
                          {item?.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
