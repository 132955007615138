import React, { useContext, useState } from "react";
import { BsHandbag } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Simplecontext } from "../Component.js/Simplecontext";
import { FaEnvelope, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import Sidebar from "./Components/Sidebar";
import MobileMenu from "./Components/MobileMenu";

export default function Header2() {
  const { setpromodal1 } = useContext(Simplecontext);
  const [active, setactive] = useState(false);
  const [rightSidebar, setrightSidebar] = useState(false);
  const [leftSidebar, setleftSidebar] = useState(false);
  let navigate = useNavigate();
  const profilehandler = () => {
    if (window.localStorage.getItem("fotoframe_usertoken")) {
      return navigate("/profile");
    } else {
      setpromodal1(true);
    }
  };
  return (
    <div>
      <header className="wrapper bg-soft-primary">
        <ToastContainer />
        <nav
          className="navbar navbar-expand-lg extended navbar-light navbar-bg-light caret-none navbar-clone fixed navbar-stick"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="header-top">
            <h4 className="header-top-text">
              Order Now & Get FREE Shipping Across UAE | Min Purchase AED 100
            </h4>
          </div>
          <div className="container flex-lg-column">
            <div className="topbar d-flex flex-row w-100 justify-content-between align-items-center">
              <div className="justify-content-between align-items-center">
                <a href="/">
                  <img
                    src="\assets\img\photos\fotoframe-logo2.png"
                    style={{ width: "70px", height: "70px" }}
                    alt="img"
                  />
                </a>
              </div>
              <div className="navbar-other ms-auto">
                <ul className="navbar-nav flex-row align-items-center">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cart">
                      {/* <i className="uil uil-info-circle" /> */}
                      <BsHandbag size={25} />
                    </Link>
                  </li>

                  <li className="nav-item">
                    <button
                      aria-controls="offcanvasExample"
                      className="offcanvas-bs"
                      onClick={() => setrightSidebar(true)}
                    >
                      <span />
                    </button>
                  </li>
                </ul>
                {/* /.navbar-nav */}
              </div>
              {/* /.navbar-other */}
            </div>
            {/* /.d-flex */}
            <div className="navbar-collapse-wrapper bg-white d-flex flex-row align-items-center">
              <div
                className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ${
                  active ? "show" : ""
                }`}
              >
                <div className="offcanvas-header d-lg-none">
                  <h3 className="text-white fs-30 mb-0">Fotoframe</h3>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    onClick={() => setactive(!active)}
                  />
                </div>
                <div className="offcanvas-body d-flex flex-column h-100">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-none d-lg-flex align-items-center">
                      <li className="nav-item" style={{ listStyle: "none" }}>
                        <button
                          onClick={() => setleftSidebar(true)}
                          aria-controls="offcanvasExample"
                          // onClick={()=>setactive(!active)}
                          style={{ padding: "20px" }}
                          className="offcanvas-bs"
                        >
                          <span />
                        </button>
                      </li>
                      <div className="navbar-brand">
                        <Link to="/">
                          <img
                            src="\assets\img\photos\fotoframe-logo2.png"
                            style={{ width: "70px", height: "70px" }}
                            alt="img"
                          />
                        </Link>
                      </div>
                    </div>

                    {/* /.navbar-nav */}
                  </div>
                </div>
              </div>

              {/* /.navbar-collapse */}
              <div className="navbar-other ms-auto w-100 d-none d-lg-block">
                <nav className="nav social-muted justify-content-end text-end flex-row align-items-center">
                  <ul
                    className="navbar-nav ms-auto d-flex align-items-center"
                    style={{ gap: "12px" }}
                  >
                    {/* <li className="d-none d-lg-block">
                  <div className="navbar-brand"><Link to="/"><img src="\assets\img\photos\fotoframe-logo2.png"  style={{width:"70px",height:"70px"}}  alt="img" /></Link></div>
                    
                  </li> */}

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link"
                        onClick={() => setactive(!active)}
                        to="/"
                      >
                        HOME
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link"
                        onClick={() => setactive(!active)}
                        to="/frames"
                      >
                        FRAMES
                      </Link>
                      {/* <a className="nav-link dropdown-toggle" href="/" data-bs-toggle="dropdown">FRAMES</a> */}
                      {/* <ul className="dropdown-menu mega-menu mega-menu-dark mega-menu-img">
                      <li className="mega-menu-content">
                        <ul className="row row-cols-1 row-cols-lg-6 gx-0 gx-lg-6 gy-lg-4 list-unstyled">
                          <li className="col"><Link className="dropdown-item" onClick={()=>setactive(!active)}   to="/papertype">
                              <div className="rounded img-svg d-none d-lg-block p-4 mb-lg-2"><img className="rounded-0" src="\assets\img\photos\print-ff.jpg" width={107} alt="img"/></div>
                              <span>Print</span>
                            </Link>
                          </li>
                          <li className="col"><Link className="dropdown-item" onClick={()=>setactive(!active)}  to="/framestype">
                              <div className="rounded img-svg d-none d-lg-block p-4 mb-lg-2"><img className="rounded-0" src="\assets\img\photos\miniframe.jpg" width={107} alt="img"/></div>
                              <span>Frames</span>
                            </Link>
                          </li>
                          <li className="col"><Link className="dropdown-item" onClick={()=>setactive(!active)}  to='/college'>
                              <div className="rounded img-svg d-none d-lg-block p-4 mb-lg-2"><img className="rounded-0"src="\assets\img\photos\photo-collage.jpg" width={107} alt="img"/></div>
                              <span>College</span>
                            </Link>
                          </li>
                          <li className="col"><Link className="dropdown-item" onClick={()=>setactive(!active)}  to='/canvastype'>
                              <div className="rounded img-svg d-none d-lg-block p-4 mb-lg-2"><img className="rounded-0" src="\assets\img\photos\9206.jpg" width={107} alt="img"/></div>
                              <span>Canvas</span>
                            </Link>
                          </li>
                         
                        </ul>
                       
                      </li>
                     
                    </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link"
                        to="/shopping"
                        onClick={() => setactive(!active)}
                      >
                        OUR STORE
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link" to="/about-us">
                        ABOUT US
                      </Link>
                    </li>

                    <li
                      className="nav-item dropdown"
                      onClick={() => setactive(!active)}
                    >
                      <ul
                        onClick={() => profilehandler()}
                        className="nav-link"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        PROFILE
                      </ul>
                    </li>
                  </ul>

                  <div className="mail-round-btn">
                    <a
                      href="mailto:fotoframe@gmail.com"
                      style={{ color: "#3e4192" }}
                    >
                      <FaEnvelope
                        style={{ marginRight: "8px", marginBottom: "3px" }}
                      />
                      fotoframes@gmail.com
                    </a>
                  </div>

                  <a href="tel:04-451 9623">
                    <div className="phone-round-btn">
                      <FaPhoneAlt
                        style={{
                          marginRight: "8px",
                          fontSize: "14px",
                          marginBottom: "3px",
                        }}
                      />
                      04-451 9623
                    </div>
                  </a>

                  <li className="nav-item social">
                    <Link className="nav-link" to="/cart">
                      {/* <i className="uil uil-info-circle" /> */}
                      <BsHandbag size={25} />
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                  <button  data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"  
                  
                   className="offcanvas-bs "><span /></button>
                </li>   */}
                </nav>
                {/* /.social */}
              </div>
              {/* /.navbar-other */}
            </div>
            {/* /.navbar-collapse-wrapper */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.navbar */}
        <div
          className="offcanvas offcanvas-end text-inverse"
          id="offcanvas-info"
          data-bs-scroll="true"
        >
          <div className="offcanvas-header">
            <h3 className="text-white fs-30 mb-0">Sandbox</h3>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body pb-6">
            <div className="widget mb-8">
              <p>
                Sandbox is a multipurpose HTML5 template with various layouts
                which will be a great solution for your business.
              </p>
            </div>
            {/* /.widget */}
            <div className="widget mb-8">
              <h4 className="widget-title text-white mb-3">Contact Info</h4>
              <address>
                {" "}
                Moonshine St. 14/05 <br /> Light City, London{" "}
              </address>
              <a href="cdn-cgi/l/email-protection.html#4e28273c3d3a60222f3d3a0e2b232f2722602d2123">
                <span
                  className="__cf_email__"
                  data-cfemail="7d14131b123d18101c1411531e1210"
                >
                  [email&nbsp;protected]
                </span>
              </a>
              <br /> 00 (123) 456 78 90
            </div>
            {/* /.widget */}
            <div className="widget mb-8">
              <h4 className="widget-title text-white mb-3">Learn More</h4>
              <ul className="list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li><a href="/">Our Story</a></li> */}
                <li>
                  <Link to="/">Projects</Link>
                </li>
                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            {/* /.widget */}
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Follow Us</h4>
              <nav className="nav social social-white">
                <a href="/">
                  <i className="uil uil-twitter" />
                </a>
                <a href="/">
                  <i className="uil uil-facebook-f" />
                </a>
                <a href="/">
                  <i className="uil uil-dribbble" />
                </a>
                <a href="/">
                  <i className="uil uil-instagram" />
                </a>
                <a href="/">
                  <i className="uil uil-youtube" />
                </a>
              </nav>
              {/* /.social */}
            </div>
            {/* /.widget */}
          </div>
          {/* /.offcanvas-body */}
        </div>
        {/* /.offcanvas */}

        {/*/.modal */}
      </header>

      {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
  Button with data-bs-target
</button> */}

      <Sidebar leftSidebar={leftSidebar} setleftSidebar={setleftSidebar} />

      {/* { rightSidebar && ( */}
      <MobileMenu
        rightSidebar={rightSidebar}
        setrightSidebar={setrightSidebar}
        profileFunction={profilehandler}
      />
      {/* )} */}
    </div>
  );
}
