import React, { useContext, useState } from 'react'
import { Simplecontext } from './Simplecontext'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { BaseUrl } from './Url';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify';

export default function Forgotpassword() {
    const {promodal1,promodal2,setpromodal1,setpromodal2 } = useContext(Simplecontext)
    const navigate = useNavigate();
    const [signpassword,setsignpassword]=useState('')
    const [signrepassword,setsignrepassword]=useState('')
    const  urlparam  = useParams()
   
    let urlid = urlparam.id

    const notify = (msg) => toast.success(msg, {
        position: "top-left",
        theme: "dark",
        });
    const notifyerror = (msg) => toast.error(msg, {
        position: "top-left",
        theme: "dark",
        });

    const ResetpasswordHandler =async(e)=>{
        e.preventDefault()
        try {
            if(signpassword === signrepassword){
                let body = {
                    method:"post",
                    url:BaseUrl+"user/passwordchange/",
                    data: {id:urlid,password:signpassword}
                  }
                  let data = await axios(body)
               
                  if (data.data.Status===200){
                    setpromodal2(false) 
                    setpromodal1(true)
                    navigate("/")
                  }else{

                  }
            }else{
                notifyerror("Please provide same password")
            }
            
        } catch (error) {
            
        }
    }
  return (
    <div>
        <ToastContainer/>
        <div className="container" style={{marginTop: 40}}>
  <div className="row">
    <div className="col-sm-12">
      <div className="horizontal-container">
        
        <div className="horizontal-form-box">
          <div className="horizontal-info-container text-center">
            {/* <img src="https://static.stayjapan.com/assets/top/icon/values-7dd5c8966d7a6bf57dc4bcd11b2156e82a4fd0da94a26aecb560b6949efad2be.svg" /> */}
            <p className="horizontal-heading">Reset your password</p>
            {/* <p className="horizontal-subtitle">Reset your password here.</p> */}
          </div>
          <div className="container  boxshadow"  >
        {/* <button type="button" onClick={()=>setpromodal2(!promodal2)} className="btn-close"  /> */}
        
        {/* <h2 className="mb-3 text-start">Reset Password</h2>
        <p className="lead mb-6 text-start">Reset your password.</p> */}
        <form onSubmit={(e)=>ResetpasswordHandler(e)} className="text-start mb-3">
          
          
          <div className="form-floating mb-4">
            <input required onChange={(e)=>setsignpassword(e.target.value)} value={signpassword} type="password" className="form-control" placeholder="Password"  />

            <label htmlFor="">Password</label>
          </div>
          <div className="form-floating  mb-4">
            <input required onChange={(e)=>setsignrepassword(e.target.value)} value={signrepassword} type="password" className="form-control" placeholder="Confirm Password"  />

            <label htmlFor="">Confirm Password</label>
          </div>
          <button type='submit' className="btn btn-primary rounded-pill btn-login w-100 mb-2">Reset</button>
        </form>
        {/* /form */}
        <p className="mb-0">Don't have an account? <a href="#" onClick={()=>setpromodal1(false) & setpromodal2(true) & navigate("/") }  className="hover">Sign up</a></p>
        
        {/*/.social */}
      </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
