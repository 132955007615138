import React from "react";

export default function GuideArea() {
  return (
    <>
      <section className="wrapper pt-14 pt-md-16">
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5">
              <h3 className="display-4 mb-9 text-center how-it-works-title">
                HOW IT WORKS
              </h3>
              <p className="how-it-works-description text-center">
                Frame your memories and get
                <br /> them delivered to your doorstep{" "}
              </p>
            </div>
            {/*/column */}
            <div className="col-lg-7 p-0">
              <div className="d-flex flex-row mb-6 justify-content-end">
                <div className="step-guide-container position-relative">
                  <div className="step-icon-wrapper">
                    <span className="icon step-icon btn btn-circle btn-soft-primary pe-none me-5">
                      <span className="number fs-36">1</span>
                    </span>
                  </div>
                  <h6 className="step-guide-text my-1">Upload your photo</h6>
                </div>
              </div>
              <div className="d-flex flex-row mb-6 justify-content-end">
                <div className="step-guide-container position-relative">
                  <div className="step-icon-wrapper">
                    <span className="icon step-icon btn btn-circle btn-soft-primary pe-none me-5">
                      <span className="number fs-36">2</span>
                    </span>
                  </div>
                  <h6 className="step-guide-text my-1">
                    Select a handmade frame
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end">
                <div className="step-guide-container position-relative">
                  <div className="step-icon-wrapper">
                    <span className="icon step-icon btn btn-circle btn-soft-primary pe-none me-5">
                      <span className="number fs-36">3</span>
                    </span>
                  </div>
                  <h6 className="step-guide-text my-1">
                    A customized frame will be delivered for display!
                  </h6>
                </div>
              </div>
            </div>
            {/*/column */}
          </div>
        </div>
      </section>
    </>
  );
}
