import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Scripts from "./Scripts";
import Signmodal from "./Signmodal";
import { Simplecontext } from "./Simplecontext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Header2 from "../NewDesignPages/Header2";
import Footer2 from "../NewDesignPages/Footer2";
export default function Outlethome() {
  const { promodal1, promodal2, setpromodal1, setpromodal2 } =
    useContext(Simplecontext);
  useEffect(() => {
    Scripts();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        {/* <Header/> */}
        <Header2 />
        <ToastContainer />
        <Outlet />
        <Signmodal />
      </div>
      {/* <Footer/> */}
      <Footer2 />

      <a
        target="_blank"
        href="https://api.whatsapp.com/send?phone=044519623&text="
        style={{ bottom: 80, right: 30, position: "fixed", zIndex: 99999999 }}
      >
        <img
          style={{ height: 55, width: 55 }}
          src="https://aetest.samaan.ae/uploads/lib/wtsapp.png"
          alt="WhatsApp chat"
        />
      </a>
    </div>
  );
}
